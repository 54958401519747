//=require /swiper.js
//=require /fotorama.js

if (typeof jQuery === 'undefined') {
    throw new Error('Frontend\'s JavaScript requires jQuery')
}
if (typeof ymaps === 'undefined') {
    throw new Error('Frontend\'s JavaScript requires Yandex Map')
}

var frontend = {
    version: 'frontend version 0.1',
}

frontend.api = {

    plugins: {
        yandexMaps: [],
        swiper: [],
        fotorama: []
    },
    componentIndex: [],

    init: function () {

        console.log(frontend.version);
        this.initPlugins();
 
    },

    getComponentPlugin: function (component){

        var componentIndex = component.dataset.componentIndex;
        if(typeof componentIndex === 'undefined') return null;

        return frontend.api.componentIndex[componentIndex];


    },

    initComponentIndex: function () {

        $('.component').each(function (index) {
            this.dataset.componentIndex = index;
            frontend.api.componentIndex.push(index);
        });

    },
    destroyComponentIndex: function(){

        $('.component').each(function (index) {
            this.removeAttribute('data-component-index');
        });

        frontend.api.componentIndex = [];


    },

    initPlugins: function () {

        frontend.api.initComponentIndex();

        // инициализация плагинов

        // Функция ymaps.ready() будет вызвана, когда
        // загрузятся все компоненты API, а также когда будет готово DOM-дерево.
        ymaps.ready(frontend.api.initYandexMaps);
        frontend.api.initFotorama();
       
    },

    destroyPlugins: function () {

        frontend.api.destroyYandexMaps();
        frontend.api.destroyFotorama();
        frontend.api.destroyComponentIndex();

        frontend.api.plugins = {
            yandexMaps: [],
            swiper: [],
            fotorama: []
        };

        console.log('destroyPlugins', frontend.api.componentIndex, frontend.api.plugins );

    },

    initYandexMaps: function () {

        console.log('frontend.api.initYandexMap');

        $('.component-map').each(function () {

            var componentIndex = this.dataset.componentIndex;
            if(typeof componentIndex === 'undefined') return 0;

            var lat = this.dataset.mapLat;
            var lng = this.dataset.mapLng;
            var label = this.dataset.mapLabel ? this.dataset.mapLabel : '';
            var zoom = this.dataset.mapZoom ? this.dataset.mapZoom : 16;
            var height = this.dataset.mapHeight ? this.dataset.mapHeight : 400;
            var myMap;

            var componentContent = $(this).find('.component-content')[0];

            if(typeof componentContent === 'undefined') return 0;

            $(componentContent).css('height', height + 'px');
            $(componentContent).html('');

            console.log('frontend.api.initYandexMap', lat, lng, label, componentContent);

              // Создание карты.    
              myMap = new ymaps.Map(componentContent, {
                  // Координаты центра карты. Порядок по умолчанию: «широта, долгота».
                  center: [ lat, lng ],
                  // Уровень масштабирования. Допустимые значения: от 0 (весь мир) до 19.
                  zoom: zoom,
                 // controls: ['smallMapDefaultSet']
              });

              myMap.behaviors.disable('scrollZoom');

              // Вспомогательный класс, который можно использовать
              // вместо GeoObject c типом геометрии «Point» (см. предыдущий пример)
              var myPlacemark = new ymaps.Placemark(
                [ lat, lng ], 
                { iconCaption: label }, 
                { preset: 'islands#blueDotIconWithCaption' }
              );

              myMap.geoObjects.add(myPlacemark);
      
              frontend.api.plugins.yandexMaps[index] = myMap;
              frontend.api.componentIndex[componentIndex] = myMap;

             
        });

        console.log('frontend.api.plugins.yandexMaps.length',  frontend.api.plugins.yandexMaps.length );


    },
    destroyYandexMaps: function () {

        console.log('frontend.api.plugins.destroyYandexMap()');

        // останавливаем плагин Yandex Map
         
        $(frontend.api.plugins.yandexMaps).each(function (index) {
            if(typeof frontend.api.plugins.yandexMaps[index].destroy === 'function') {
                frontend.api.plugins.yandexMaps[index].destroy();
            } 
            frontend.api.plugins.yandexMaps[index] = null;
            console.log('frontend.api.plugins.yandexMaps [' + index + '].destroy()');
        });

    },
    initFotorama: function () {

        console.log('frontend.api.initFotorama()');

       
         // запускаем плагин Fotorama

        $('.component-fotorama').each(function (index) {

            var componentIndex = this.dataset.componentIndex;
            if(typeof componentIndex === 'undefined') return 0;
    
    
    
            var fotoramaElement = $(this).find('.fotorama')[0];
            if(typeof fotoramaElement === 'undefined') return 0;
    
            console.log('fotorama find element', index, componentIndex);
    
            // 1. Initialize fotorama manually.
            var $fotoramaDiv = $(fotoramaElement).fotorama();

            // 2. Get the API object.
            var fotorama = $fotoramaDiv.data('fotorama');
        
            frontend.api.plugins.fotorama[index] = fotorama;

            frontend.api.componentIndex[componentIndex] = fotorama;

        });

        console.log('frontend.api.plugins.fotorama.length',  frontend.api.plugins.fotorama.length );
    
    },

    destroyFotorama: function () {

        console.log('frontend.api.plugins.destroyFotorama()');

         // останавливаем плагин Fotorama

        $(frontend.api.plugins.fotorama).each(function (index) {
            if(typeof frontend.api.plugins.fotorama[index].destroy === 'function') {
                frontend.api.plugins.fotorama[index].destroy();
            } 
            frontend.api.plugins.fotorama[index] = null;
            console.log('frontend.api.plugins.fotorama [' + index + '].destroy()');
        });

    },

    initSwiper: function () {

        console.log('frontend.api.initSwiper()');

         // запускаем плагин Swiper
        
        $('.component-swiper').each(function (index) {
    
    
            var swiperElement = $(this).find('.gallery-top')[0];
            var swiperThumbsElement = $(this).find('.gallery-thumbs')[0];
    
            if(!swiperElement || !swiperThumbsElement ) return;
    
            console.log(".component-swiper", index, swiperElement, swiperThumbsElement);
    
            var thumbs = new Swiper(swiperThumbsElement, {
                spaceBetween: 10,
                slidesPerView: 5,
                loop: true,
                //freeMode: true,
                loopedSlides: 0, //looped slides should be the same
                watchSlidesVisibility: true,
                watchSlidesProgress: true,
            });
    
            var swiper = new Swiper(swiperElement, {
                spaceBetween: 10,
                loop:true,
                loopedSlides: 0, //looped slides should be the same
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                thumbs: {
                    //swiper: galleryThumbs,
                    swiper: thumbs,   
                },
            });

            frontend.api.plugins.swiper[index] = {
                thumbs: thumbs,
                swiper: swiper
            }
    
        });
    }

}



$(document).ready(function () {
    
    
    console.log("JQUERY Объектная модель готова к использованию!");


    $('.js-form').on("click", ".js-form-submit", function (e) {

        e.preventDefault();

        var form = $(this.form);

        var csrfParam = $('meta[name="csrf-param"]').attr("content");
        var csrfToken = $('meta[name="csrf-token"]').attr("content");

        var data = form.serialize();

        $.ajax({

            url: '/order/send',
            type: 'POST',
            data: data + '&_csrf=' + csrfToken ,

        }).done(function(data) {
            if(data.success) {

                // данные сохранены
                console.log('Данные сохранены');
                // очищает форму
                $(form)[0].reset();

                // ym(51922136, 'reachGoal', 'BTN_ORDER');

                if(typeof (ym) === "function" && setting.yandexMetrika){
                    ym(setting.yandexMetrika, 'reachGoal', 'BTN_SEND_FORM');        
                 }else{
                     console.log('goal', 'Ошибка отправки цели!', 'BTN_SEND_FORM');
                 }

                // редирект
                if(data.redirect){
                    window.location.href = data.url ? data.url : "/order/index";
                }

            } else {
                // сервер вернул ошибку и не сохранил наши данные
                if(data.errors){

                    var text = '';

                    for (var key in data.errors) {
                        if (data.errors.hasOwnProperty(key)) {
                            text = text +  data.errors[key].toString();
                            text = text + '<br>';
                        }
                    }

                    $(form).find('.component-form-error').html('<p class="form-error">' + text +'</p>').addClass('visible');

                }
                // console.log(data)
            }
        })
            .fail(function () {
                // не удалось выполнить запрос к серверу
            });

        return false;

        console.log('submit');

    });


    $('[data-goal]').click(function (e) {
  
        console.log('goal', setting.yandexMetrika, this.dataset.goal);
        
        if(this.dataset.goal && typeof (ym) === "function" && setting.yandexMetrika){
           ym(setting.yandexMetrika, 'reachGoal', this.dataset.goal);        
        }else{
            console.log('goal', 'Ошибка отправки цели!');
        }

    })

    
    $('.component-menu > .component-menu-content > ul > li > a').each(function () {

        $url = encodeURI($(this).attr('href'));
        console.log("Меню", $(this).attr('href'), location.href, location.pathname );
        if($url == location.href || $url == location.pathname) $(this).addClass('active');
    
    });

    
    frontend.api.init();

});






